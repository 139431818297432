<template>
  <div>
    <v-row v-if="currentProject && currentProject.id">
      <v-row class="w-full pa-2">
        <v-alert
          v-model="addListAlert"
          dismissible
          class="w-full ma-1"
          type="error"
          transition="slide-y-transition"
          elevation="2"
        >
          {{ errorMsg }}
        </v-alert>
      </v-row>
      <v-card class="col-12 mt-0 pa-6 mb-6">
        <v-row>
          <div class="col-1 pa-0">
            <v-emoji-picker
              :class="{hidden: !emojiPicker}"
              class="emoji-picker"
              @select="selectEmoji"
            />
            <v-icon
              v-if="!newListEmoji && !emojiPicker"
              class="mr-2 pa-2 d-block"
              @click="emojiPicker = !emojiPicker"
            >
              {{ icons.mdiStickerEmoji }}
            </v-icon>
            <span
              v-if="newListEmoji && !emojiPicker"
              class="text-3xl pa-4 new-list-emoji-container"
              @click="emojiPicker = !emojiPicker"
            >
              {{ newListEmoji }}
            </span>
            <v-icon
              v-if="emojiPicker"
              class="mr-2 pa-2 d-block"
              @click="emojiPicker = !emojiPicker"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </div>
          <v-text-field
            v-model="newListName"
            class="col-auto mr-4"
            style="min-width: 70%;"
            hide-details="auto"
            outlined
            dense
            label="New List Name"
            @keyup.enter="addList"
          ></v-text-field>
          <v-btn
            outlined
            class="col-2"
            style="margin-top: 1px"
            :disabled="!this.newListName"
            @click="addList"
          >
            Add List
          </v-btn>
        </v-row>
      </v-card>

      <v-row>
        <!--      <div class="col-12 w-full">-->
        <!--        <v-card class="col-12 w-full">-->
        <!--          <v-card-title class="pa-0 pb-5">-->
        <!--            Filters-->
        <!--          </v-card-title>-->
        <!--          <v-row>-->
        <!--            <v-col class="col-4">-->
        <!--              <v-select-->
        <!--                outlined-->
        <!--                dense-->
        <!--                clearable-->
        <!--                multiple-->
        <!--                small-chips-->
        <!--                label="Priorities"-->
        <!--                :items="Object.values(getPriorities()).map(e => e.label)"-->
        <!--              ></v-select>-->
        <!--            </v-col>-->

        <!--            <v-col class="col-4">-->
        <!--              <v-select-->
        <!--                outlined-->
        <!--                dense-->
        <!--                clearable-->
        <!--                multiple-->
        <!--                small-chips-->
        <!--                label="Assigned"-->
        <!--                :items="Object.values(members).map(e => e.name + ' (' + e.email + ')')"-->
        <!--              ></v-select>-->
        <!--            </v-col>-->

        <!--            <v-col class="col-4">-->
        <!--              <v-select-->
        <!--                outlined-->
        <!--                dense-->
        <!--                clearable-->
        <!--                label="Due To"-->
        <!--                :items="['Overdue', 'Today', 'Up Coming', 'No Date']"-->
        <!--              ></v-select>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-card>-->
        <!--      </div>-->

        <div
          v-for="(list, i) in [processedList.uncategorized, ...Object.values(processedList.lists || [])]"
          v-if="list"
          :key="i"
          class="col-12 w-full"
        >
          <v-card class="col-12 w-full">
            <v-card-title
              v-if="!isUncategorized(list)"
              class="pa-0 pb-5 list-title"
            >
              <span
                v-if="list.emoji"
                class="list-title-emoji text-3xl pr-2"
              >{{ list.emoji }}</span>
              {{ list.name }}
            </v-card-title>
            <v-row>
              <v-simple-table class="w-full">
                <template v-slot:default>
                  <draggable
                    tag="tbody"
                    :list="list.tasks"
                    class="list-group"
                    draggable=".list-group-item"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <tr
                      v-for="(element) in list.tasks"
                      v-if="(showFinished || !element.finished)"
                      :key="element.name"
                      class="list-group-item w-full"
                      :class="{finished: element.finished || element._in_sync}"
                    >
                      <td
                        class="col-1"
                        style="width: 18px"
                      >
                        <v-simple-checkbox
                          v-model="element.finished"
                          value="1"
                          style="width: 18px;"
                          type="checkbox"
                          class="mt-1 ml-2"
                          :ripple="false"
                          :disabled="!element.text"
                          @click="taskChanged(element)"
                        ></v-simple-checkbox>
                      </td>
                      <td class="col-7 col-sm-9">
                        <v-text-field
                          v-if="!element.finished"
                          v-model="element.text"
                          filled
                          class="information-field pa-0"
                          hide-details="auto"
                          placeholder="To Do Text"
                          v-bind="{autofocus: !element.text}"
                          @focusout="textFocusOut(element)"
                          @keyup.enter="addTask(isUncategorized(list) ? null : list)"
                          @change="taskChanged(element)"
                        ></v-text-field>
                        <span
                          v-if="element.finished"
                          class="information-text"
                        >{{ element.text }}</span>
                      </td>
                      <td class="col-1">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              small
                              class="font-weight-medium"
                              :class="getPriorityBadgeClass(element)"
                              outlined
                              v-bind="attrs"
                              v-on="(!element.finished && element.text) ? on : undefined"
                            >
                              {{ element.finished ? 'Done' : getPriorities()[element.priority].label }}
                            </v-chip>
                          </template>
                          <v-list class="pa-0">
                            <v-list-item
                              class="priority-item"
                              v-for="(priority) in Object.values(getPriorities()).sort((a,b) => b.id - a.id)"
                              dense
                              :v-key="priority.id"
                              @click="setPriority(element, priority.id)"
                            >
                              <v-list-item-title :class="priority.class + '--text'">
                                {{ priority.label }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>

                      <td class="col-1">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar
                              class="task-assigned-avatar"
                              v-bind="{color: element.assigned_to ? 'primary' : ''}"
                              size="36"
                              v-on="on"
                            >
                              <v-icon
                                v-if="!element.assigned_to"
                                size="24"
                              >
                                {{ icons.mdiAccountPlus }}
                              </v-icon>
                              <span v-if="element.assigned_to && getMember(element.assigned_to)">
                                <span
                                  v-if="!getMember(element.assigned_to).img"
                                  class="white--text"
                                >
                                  {{ getMember(element.assigned_to).short_name }}
                                </span>
                                <v-img
                                  v-if="getMember(element.assigned_to).img"
                                  :src="getMember(element.assigned_to).img"
                                ></v-img>
                              </span>
                            </v-avatar>
                          </template>
                          <v-list class="pa-0">
                            <v-list-item
                              v-if="element.assigned_to"
                              @click="setAssignedTo(element)"
                            >
                              <v-list-item-title>
                                <v-icon size="24">
                                  {{ icons.mdiClose }}
                                </v-icon>
                                Un-assign
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              dense
                              v-for="member in members"
                              @click="setAssignedTo(element, member)"
                            >
                              <v-list-item-title>
                                <v-avatar
                                  color="primary"
                                  size="24"
                                  class="white--text text-caption"
                                >
                                  {{ member.short_name }}
                                </v-avatar>
                                <span>
                                  {{ member.user_name || member.user_email }}
                                </span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>

                      <td class="col-1">
                        <v-menu
                          v-model="element._dateMenu"
                          :nudge-left="200"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-row>
                              <v-col class="col-2">
                                <v-icon
                                  size="18"
                                  class="mt-0"
                                  :class="getDateClass(element.date)"
                                  v-on="on"
                                >
                                  {{ icons.mdiCalendar }}
                                </v-icon>
                              </v-col>
                              <v-col
                                v-if="element.date"
                                class="col-10"
                              >
                                <v-text-field
                                  v-model="element.date"
                                  readonly
                                  clearable
                                  hide-details
                                  class="date-input"
                                  :class="getDateIntClass(element)"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click:clear="dateCleared(element)"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </template>
                          <v-date-picker
                            v-model="element.date"
                            @input="element._dateMenu = false"
                            @change="taskChanged(element)"
                          ></v-date-picker>
                        </v-menu>
                      </td>
                      <td class="col-1">
                        <v-icon
                          class="delete-task-btn"
                          @click="removeTask(element)"
                        >
                          {{ icons.mdiDelete }}
                        </v-icon>
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td colspan="6">
                        <button
                          class="btn btn-secondary button"
                          @click="addTask(isUncategorized(list) ? null : list)"
                        >
                          <span
                            v-if="!list.tasks.length"
                            class="text-center pa-4 pr-1"
                          >
                            No Tasks Yet, Add First
                          </span>
                          <v-icon class="pr-3">
                            {{ icons.mdiPlus }}
                          </v-icon>
                          <span v-if="!isUncategorized(list) && !list.tasks.length">
                            Or Delete This List
                          </span>
                          <v-icon
                            v-if="!isUncategorized(list) && !list.tasks.length"
                            class="pl-1"
                            @click="removeList(list)"
                          >
                            {{ icons.mdiDelete }}
                          </v-icon>
                        </button>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-simple-table>
            </v-row>
          </v-card>
        </div>
      </v-row>

      <div class="w-full pt-12">
        <v-btn
          rounded
          @click="showFinished = !showFinished"
        >
          {{ showFinished ? 'Hide' : 'Show' }} Finished
        </v-btn>
      </div>
    </v-row>

    <choose-project v-if="!currentProject || !currentProject.id"></choose-project>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { mapState } from 'vuex'
import { VEmojiPicker } from 'v-emoji-picker'
import {
  mdiPlus,
  mdiDelete,
  mdiAccount,
  mdiCalendar,
  mdiClose,
  mdiAccountPlus,
  mdiStickerEmoji,
} from '@mdi/js'
import ChooseProject from './ChooseProject'

export default {
  components: {
    Draggable,
    VEmojiPicker,
    ChooseProject,
  },
  data() {
    return {
      errorMsg: '',
      addListAlert: false,

      newListEmoji: '',
      emojiPicker: false,

      newListName: '',
      dragging: false,
      menu2: false,

      showFinished: true,

      listHeader: [
        { text: '', value: 'finished' },
        { text: 'Name', value: 'text' },
        { text: 'Priority', value: 'priority' },
        { text: 'Assigned', value: 'assigned' },
        { text: 'Date', value: 'date' },
      ],

      icons: {
        mdiClose,
        mdiDelete,
        mdiAccount,
        mdiStickerEmoji,
        mdiAccountPlus,
        mdiCalendar,
        mdiPlus,
      },

      processedList: [],

      filters: {},
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    ...mapState({
      members: 'currentProjectUsers',
      tasks: 'todoTasks',
      lists: 'todoLists',
      currentProject: 'currentProject',
    }),
  },
  watch: {
    lists() { this._reProcessList() },
    tasks() { this._reProcessList() },
    async currentProject() {
      await this.$store.dispatch('setSectionOverlay', true)
      await this.$store.commit('SET_TODO_LISTS', [])
      await this.$store.commit('SET_TODO_TASKS', [])
      await this.$store.dispatch('fetchTodos')
      await this.$store.dispatch('getUsersList')
      await this.$store.dispatch('setSectionOverlay', false)
    },
  },
  async mounted() {
    await this.$store.dispatch('setSectionOverlay', true)
    await this.$store.dispatch('fetchTodos')
    await this.$store.dispatch('getUsersList')
    await this.$store.dispatch('setSectionOverlay', false)
  },
  methods: {
    addList() {
      if (!this.newListName) {
        return
      }

      const newList = { name: this.newListName, emoji: this.newListEmoji, _new: true }
      newList._internal_key = this.__getListKey(newList)

      let listExists = false
      Object.values(this.lists).map(list => {
        if (this.__getListKey(list) === newList._internal_key) {
          listExists = true
        }
      })
      if (listExists) {
        this.__showError('Error creating new list. List with the same name already exists.')

        return
      }

      this.lists.push(newList)

      this.newListName = ''
      this.newListEmoji = ''

      this.addTask(newList)
      this.listChanged()
    },
    removeList(list) {
      list._deleted = true
      this.listChanged()
    },

    addTask(list) {
      const task = {
        text: '', priority: 1, date: '', _menu2: false, _skip_processing: true,
      }
      if (list) {
        if (list.id) task.list_id = list.id
        else task.list_internal_key = list._internal_key
      }

      let haveEmpty = false
      this.getListTasks(list).map(task => {
        if (!task._deleted && !task.text) {
          haveEmpty = true
        }
      })

      if (haveEmpty) {
        return
      }

      this.tasks.push(task)
      this.taskChanged()
    },
    async removeTask(task) {
      task._deleted = true
      if (!task.text && !task.id && !task._in_sync) {
        task._skip_processing = false
      }
      await this.$store.dispatch('setTodoTasks', this.tasks)
    },

    _reProcessList() {
      const processedList = {
        uncategorized: {
          _uncategorized: true,
          tasks: [],
        },
        lists: {},
      }

      this.lists.map(list => {
        if (!list.tasks) {
          list.tasks = []
        }
        processedList.lists[list.id || this.__getListKey(list)] = list
      })

      const allTasks = this.tasks.slice()
      allTasks.sort((a, b) => b.priority - a.priority)

      Object.values(processedList.lists).map(list => {
        list.tasks = []
      })

      allTasks.map(task => {
        if (task.list_id && processedList.lists[task.list_id]) {
          processedList.lists[task.list_id].tasks.push(task)
        } else if (task.list_internal_key && processedList.lists[task.list_internal_key]) {
          processedList.lists[task.list_internal_key].tasks.push(task)
        } else {
          processedList.uncategorized.tasks.push(task)
        }
      })

      const lists = [...Object.values(processedList.lists), processedList.uncategorized]
      lists.map(list => {
        const active = []
        const finished = []

        let emptyTask = false
        list.tasks.map(el => {
          if (!el.text) emptyTask = el
          else (el.finished ? finished : active).push(el)
        })

        const tasks = active.concat(finished)
        if (emptyTask) {
          tasks.push(emptyTask)
        }
        list.tasks = tasks
      })

      this.processedList = processedList

      this.$forceUpdate()
    },

    getListTasks(list, all = false) {
      const allTasks = this.tasks.slice()

      let listTasks = []
      if (all) {
        listTasks = allTasks
      } else {
        listTasks = allTasks.filter(task => {
          if (!list || !task.list_internal_key) {
            return true
          }

          return (task.list_internal_key === list._internal_key)
        })
      }

      listTasks.sort((a, b) => b.priority - a.priority)

      const active = []
      const finished = []

      listTasks.map(el => {
        (el.finished ? finished : active).push(el)
      })

      return active.concat(finished)
    },

    selectEmoji(emoji) {
      this.emojiPicker = !this.emojiPicker
      this.newListEmoji = emoji.data
    },

    async taskChanged(initialTask) {
      this.tasks.map(task => {
        if (initialTask && (initialTask.id === task.id)) {
          task._synced = false
        }

        if (task.text) {
          delete task._skip_processing
        } else {
          task._skip_processing = true
        }
      })
      await this.$store.dispatch('setTodoTasks', this.tasks)
    },
    async listChanged() {
      await this.$store.dispatch('setTodoLists', this.lists)
    },

    dateCleared(element) {
      element.date = ''
      this.taskChanged(element)
    },

    __getListKey(list) {
      return JSON.stringify({
        name: list.name,
        emoji: list.emoji,
      })
    },

    __showError(text) {
      this.errorMsg = text
      this.addListAlert = true
      setTimeout(() => {
        this.addListAlert = false
        this.errorMsg = 'false'
      }, 5000)
    },

    isUncategorized(list) {
      return !!list._uncategorized
    },

    setPriority(el, pr) {
      el.priority = pr
      this.taskChanged(el)
    },

    setAssignedTo(el, member) {
      el.assigned_to = member ? member.id : ''
      this.taskChanged(el)
    },

    getPriorityBadgeClass(element) {
      if (!element.finished && element.text) {
        const priorities = this.getPriorities()

        return [
          priorities[element.priority].class,
          `${priorities[element.priority].class}--text`,
        ]
      }

      return []
    },

    getPriorities() {
      return {
        4: {
          id: 4,
          label: 'In Work',
          class: 'primary',
        },
        3: {
          id: 3,
          label: 'Urgent',
          class: 'error',
        },
        2: {
          id: 2,
          label: 'Prioritized',
          class: 'warning',
        },
        1: {
          id: 1,
          label: 'Normal',
          class: 'success',
        },
        0: {
          id: 0,
          label: 'Hold',
          class: 'info',
        },
      }
    },
    getDateClass(date) {
      // @TODO: process timezone!!!
      const dateObj = new Date(date)
      const curDateObj = new Date()

      const diffTime = dateObj - curDateObj
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      let textClass = ''
      switch (true) {
        case !date:
          break
        case diffDays <= 0:
          textClass = 'error--text'
          break
        case diffDays > 0 && diffDays <= 3:
          textClass = 'warning--text'
          break
        case diffDays > 0:
          textClass = 'success--text'
          break
      }

      return textClass
    },
    getDateIntClass(element) {
      return element.date ? 'active' : ''
    },

    textFocusOut(element) {
      this.$nextTick(() => {
        if (!element.text) {
          this.removeTask(element)
        }
      })
    },

    getMember(id) {
      for (let m of this.members) {
        if (m.id === id) {
          return m
        }
      }
    }
  },
}
</script>
<style scoped lang="scss">
  .information-field.v-text-field > .v-input__control > .v-input__slot:before {
    display: none;
  }
  .no-msg-icon .v-messages {
    display: none;
  }
  .delete-task-btn,
  .delete-list-btn {
    opacity: 0;
    transition: .25s;
  }
  .list-group-item:hover .delete-task-btn,
  .list-title:hover .delete-list-btn {
    opacity: 1;
  }
  .delete-list-btn {
    margin-left: auto;
  }
  .emoji-picker {
    position: absolute;
    z-index: 1000;
    left: 72px;

    &.hidden {
      display: none!important;
    }
  }
  .new-list-emoji-container {
    color: #fff;
    padding: 2px 12px 0 !important;
    display: block;
    cursor: pointer;
  }
  .list-title-emoji {
    color: #fff;
  }

  .list-group-item.finished .information-text {
    text-decoration: line-through;
    opacity: 0.9;
  }

  .task-assigned-avatar {
    cursor: pointer;
  }

  .date-input {
    width: 0;
    margin: 0;
    padding: 0;

    &.active {
      width: 150px;
    }
  }
  .priority-item {
    height: 28px!important;
    min-height: 28px!important;
  }
</style>
