<template>
  <to-do-lists></to-do-lists>
</template>

<script>
import ToDoLists from '../../components/ToDoLists'

export default {
  components: {
    ToDoLists,
  },
  data() {
    return {

    }
  },
  created() {
    // watch the params of the route to fetch the data again
    // this.$watch(
    //   () => this.$route.params,
    //   () => {
    //     this.fetchData()
    //   },
    //   // fetch the data when the view is created and the data is
    //   // already being observed
    //   { immediate: true }
    // )
  },
  methods: {

  },
}
</script>
